import React, { useEffect, useState } from 'react'
import { Blurhash } from 'react-blurhash'

function srcset(image, size, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${size * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };
}

function ImageComponent({ url, hash }) {
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.onload = () => {
            setImageLoaded(true);
        }
        img.src = url
    }, [url])

    return (
        <>
            <div style={{ display: imageLoaded ? 'none' : 'inline' }}>
                <Blurhash
                    hash={hash}
                    resolutionX={32}
                    resolutionY={32}
                    punch={1}
                    width={"100%"}
                    height={"100%"}
                />
            </div>
            <img
                width={"100%"}
                height={"100%"}
                {...srcset(`${url}`, 121, 2, 3)}
                alt=''
                loading="lazy"
                style={{ display: !imageLoaded ? 'none' : 'inline' }}
            />
        </>

    )
}

export default ImageComponent