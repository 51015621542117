import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import HouseboatIcon from '@mui/icons-material/Houseboat';
import CachedIcon from '@mui/icons-material/Cached';
import { updateBooking, updateRoomType } from '../Helper/Helper';
import axios from 'axios';
import Global from '../Helper/Global';

export default function CircularIntegration({id,lodgeid,numofrooms,roomtype}) {
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef();
  const [roomTypeId, setRoomTypeId] = React.useState(null);

  React.useEffect(()=>{

    axios.get(`${Global}/api/room-types?populate=*&filters[lodges][id][$eq]=${lodgeid}&filters[type]=${roomtype}`)
      .then(res => {
        setRoomTypeId(res.data.data[0].id)
      })
      .catch(err => {
        console.log(err)
      })
  })

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleButtonClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
        updateBooking(id,'1')
        updateRoomType(roomTypeId,numofrooms)//roomid, number of rooms from the booking 
      }, 500);
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ m: 1, position: 'relative' }}>
        {success ? <CheckIcon /> : <CachedIcon />}
      </Box>
      <Box sx={{ m: 1, position: 'relative' }}>
        <Button
          variant="contained"
          sx={{ bgcolor: 'primary.main',paddingX:4,paddingY:0, ':hover': {bgcolor:'green'} }}
          disabled={loading}
          onClick={handleButtonClick}
        >
          {success ? "Arrival confirmed" : "Confirm Arrival"}
        </Button>
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              color: green[500],
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Box>
    </Box>
  );
}