import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { BsFillTrashFill } from "react-icons/bs";
import ApproveBtn from './ApproveBtn';
import { Box, Button, IconButton, Typography } from '@mui/material';
import dateFormat from "dateformat";
import { updateBooking } from '../Helper/Helper';
import SearchIcon from '@mui/icons-material/Search';
import './dash.css'
import SnackbarWithDecorators from '../components/SnackBar';

function preventDefault(event) {
  event.preventDefault();
}

export default function Tables({ bookings }) {
  const [searchTerm, setSearchTerm] = React.useState("");

  return (
    <React.Fragment>
      <Box sx={{ ml: "auto", mr: "auto", mt: 3 }}>
        <div className="input_wrapper">
          <SearchIcon sx={{ color: "royalblue" }} />
          <input placeholder="Search Customer…" onChange={(event) => { setSearchTerm(event.target.value); }} />
        </div>
      </Box >
      <Title><Typography sx={{ fontFamily: 'Katibeh', color: 'black' }} variant="h5" component="h3" marginTop={1}>List of active Bookings</Typography></Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date & time</TableCell>
            <TableCell>Username</TableCell>
            <TableCell>Contacts</TableCell>
            <TableCell>RoomType</TableCell>
            <TableCell>Prices</TableCell>
            <TableCell>status</TableCell>
            <TableCell ></TableCell>
            <TableCell ></TableCell>
            <TableCell ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bookings.filter((booking) => {
            if (searchTerm === "") {
              return booking;
            } else if (booking.attributes.user.data.attributes.username.toLowerCase().includes(searchTerm.toLowerCase())) {
              return booking;
            }
          })
            .map((booking) => (
              <TableRow key={booking.id}>
                <TableCell>{dateFormat(booking.attributes.date_time, "dddd, mmmm dS, yyyy, h:MM TT")}</TableCell>
                <TableCell>{booking.attributes.user.data.attributes.username}</TableCell>
                <TableCell>{booking.attributes.user.data.attributes.user_info.data.attributes.phoneNumber}</TableCell>
                <TableCell>{`${booking.attributes.roomType} [${booking.attributes.num_of_rooms}]`}</TableCell>
                <TableCell>R{booking.attributes.price}</TableCell>
                {(() => {
                  switch (booking.attributes.isBookingFullfilled) {
                    case '0':
                      return (<>
                        <TableCell>{"Reserved"}</TableCell>
                        <TableCell><Button sx={{ bgcolor: 'primary.main',paddingX:0, ':hover': {bgcolor:'green'} }} onClick={() => { updateBooking(booking.id, '4') }}><SnackbarWithDecorators name={"Confirm Payment"} message={"Payment Confirmed"}/></Button></TableCell>
                        <TableCell><Button sx={{ bgcolor: 'primary.main',paddingX:0, ':hover': {bgcolor:'orange'} }} onClick={() => { updateBooking(booking.id, '3') }}><SnackbarWithDecorators name={"Confirm Deposit"} message={"Deposit Confirmed"}/></Button></TableCell>
                        <TableCell><ApproveBtn id={booking.id} lodgeid={booking.attributes.lodge.data.id} numofrooms={booking.attributes.num_of_rooms} roomtype={booking.attributes.roomType} /></TableCell>
                        <TableCell><Button sx={{ bgcolor: 'primary.main',paddingX:0, ':hover': {bgcolor:'red'} }}  onClick={() => { updateBooking(booking.id, '2') }}><SnackbarWithDecorators name={"Delete"} message={"delete Confirmed"}/></Button></TableCell>
                      </>
                      )
                      break;
                    case '1':
                      return <TableCell>{"Settled"}</TableCell>
                      break;
                    case '2':
                      return <TableCell>{"Cancelled"}</TableCell>
                      break;
                    case '3':
                      return (<>
                        <TableCell>{"Deposited"}</TableCell>
                        <TableCell><Button sx={{ bgcolor: 'primary.main',paddingX:0, ':hover': {bgcolor:'green'} }} onClick={() => { updateBooking(booking.id, '4') }}><SnackbarWithDecorators name={"Confirm Payment"} message={"Payment Confirmed"}/></Button></TableCell>
                        <TableCell><ApproveBtn id={booking.id} lodgeid={booking.attributes.lodge.data.id} numofrooms={booking.attributes.num_of_rooms} roomtype={booking.attributes.roomType} /></TableCell>
                        <TableCell><Button sx={{ bgcolor: 'primary.main',paddingX:0, ':hover': {bgcolor:'red'} }}  onClick={() => { updateBooking(booking.id, '2') }}><SnackbarWithDecorators name={"Delete"} message={"delete Confirmed"}/></Button></TableCell>
                      </>
                      )
                      break;
                    case '4':
                      return (<>
                        <TableCell>{"Paid"}</TableCell>
                        <TableCell><ApproveBtn id={booking.id} lodgeid={booking.attributes.lodge.data.id} numofrooms={booking.attributes.num_of_rooms} roomtype={booking.attributes.roomType} /></TableCell>
                      </>
                      )
                      break;
                    default:
                      return null
                  }
                })()}

                {/* <TableCell>{booking.attributes.isBookingFullfilled === '0' ? <IconButton onClick={() => { updateBooking(booking.id, '2') }}><BsFillTrashFill /></IconButton> : null}</TableCell>
                <TableCell>{booking.attributes.isBookingFullfilled === '0' ? <ApproveBtn id={booking.id} lodgeid={booking.attributes.lodge.data.id} numofrooms={booking.attributes.num_of_rooms} roomtype={booking.attributes.roomType} /> : null}</TableCell>
                <TableCell><ApproveBtn id={booking.id} lodgeid={booking.attributes.lodge.data.id} numofrooms={booking.attributes.num_of_rooms} roomtype={booking.attributes.roomType} /></TableCell>
                <TableCell>{booking.attributes.isBookingFullfilled === '0' ? <ApproveBtn id={booking.id} lodgeid={booking.attributes.lodge.data.id} numofrooms={booking.attributes.num_of_rooms} roomtype={booking.attributes.roomType} /> : null}</TableCell> */}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}