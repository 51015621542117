import { useState, useRef } from 'react'
import Button from '@mui/joy/Button';
import { Typography } from '@mui/material';
import { MDBInput } from 'mdb-react-ui-kit';
import { updateUser, updateUserInfo } from '../Helper/Helper';
import toast, { Toaster } from 'react-hot-toast';

const Edit = ({type, field}) => {

  //setting a state for the incoming profile values
    const [pValue, setPValue] =  useState(type)
    const [isInEditMode, setEditMode] = useState(false)
    const inputRef = useRef(null);

    const changeEditMode = () => {
        setEditMode (!isInEditMode)
    }

    const UpdateComponentValue = () => {
        setEditMode(false)
        setPValue(inputRef.current.value)
        if(field === 'email' || field === 'username'){

          let data = {} 
          data[field] = inputRef.current.value

          let updatePromise = updateUser(data);

          toast.promise(updatePromise, {
            loading: 'Updating...',
            success : <b>Update Successfully...!</b>,
            error: <b>Could not Update!</b>
          });
        } else {
        
          let data = {data:{}} 
          data.data[field] = inputRef.current.value

          console.log(data)
          let updatePromise = updateUserInfo(data);

          toast.promise(updatePromise, {
            loading: 'Updating...',
            success : <b>Update Successfully...!</b>,
            error: <b>Could not Update!</b>
          });
        }
         
    }

    const EditView = () =>{
       return(
        <>
        <MDBInput className='border-0' type="text"
        defaultValue={pValue}
        ref={inputRef}
         />
         <Button
  color="danger"
  onClick={changeEditMode}
  size="sm"
  variant="plain"
>Cancel</Button>
<Button
  sx={{color:"#063970"}}
  onClick={UpdateComponentValue}
  size="sm"
  variant="plain"
>
    {/* <DoneIcon /> */}update
    </Button>
    </>
       ) 
    }

    const DefaultView = () => {
       return (
        <Typography component={'span'} variant={'div'} onDoubleClick={changeEditMode}>{pValue}</Typography>
       )
    }

  return (
        <>
        {isInEditMode ? (
        <EditView />
        ) : (
        <DefaultView />
        )}
        </>
            
        )
        }

export default Edit