import { CircularProgress } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import '../App.css';

const ErrorPage = () => {
    const navigate = useNavigate()
    const timer = React.useRef();

    const handleOnclick = () => {

            timer.current = window.setTimeout(() => {
                navigate('/')
                window.location.reload(true)
            }, 500);
       
    }
    return (
        <section className="container">
            <div className="clock_content">
                <h1>Oops!!!!</h1>
                <h3>Something Went Wrong</h3>
                <button onClick={handleOnclick} className="button">home page</button>
            </div>
        </section>
    )
}

export default ErrorPage