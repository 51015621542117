import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Global from "../Helper/Global";
import QuiltedImageComponent from './QuiltedImageComponent';



function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function QuiltedImageList({ itemData }) {
  return (
    <ImageList
      sx={{ width: 700, height: 400, marginLeft: 1 }}
      variant="quilted"
      cols={4}
      rowHeight={121}
    >
      {itemData.map((item) => (
        <ImageListItem
          key={item.id}
          cols={4}
          rows={3}
        >
          {/* <QuiltedImageComponent url={"http://localhost:1337"+item.attributes.url} hash={item.attributes.blurhash}/> */}
          <QuiltedImageComponent url={item.attributes.url} hash={item.attributes.blurhash}/>
        </ImageListItem>
      ))}
    </ImageList>
  );
}