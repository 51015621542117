import React from 'react';
import { Box, Typography, Container, Button, Card, CardContent } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

const EmailVerificationPage = () => {
  const handleWhatsAppClick = () => {
    const phoneNumber = '+27726837992';
    const message = encodeURIComponent("I need help with my account email verification");
    const url = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(url, '_blank');
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: '100px' }}>
      <Card elevation={3}>
        <CardContent>
          <Box textAlign="center" p={3}>
            <EmailIcon style={{ fontSize: 60, color: '#1976d2' }} />
            <Typography variant="h4" component="h1" gutterBottom>
              Verify Your Email
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Thank you for registering! We have sent a verification link to your email address.
              Please check your inbox and click the link to verify your account.
            </Typography>
            <Box mt={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleWhatsAppClick}
              >
                Contact Admin on WhatsApp
              </Button>
            </Box>
            <Box mt={2}>
              <Typography variant="body2" color="textSecondary">
                Didn't receive the email? Please check your spam folder or click the button above to contact admin on WhatsApp.
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default EmailVerificationPage;

