import { Box, Button, Grid, Stack } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import MainCard from './Jumbo/Main';
import IncomeAreaChart from './Jumbo/IncomeAreaChart';

export default function Jumbotron() {
  const [slot, setSlot] = useState('week');
  return (
    <>
      <Grid item>
        <Stack direction="row" alignItems="center" spacing={0}>
          <Button
            size="small"
            onClick={() => setSlot('month')}
            color={slot === 'month' ? 'primary' : 'secondary'}
            variant={slot === 'month' ? 'outlined' : 'text'}
          >
            Month
          </Button>
          <Button
            size="small"
            onClick={() => setSlot('week')}
            color={slot === 'week' ? 'primary' : 'secondary'}
            variant={slot === 'week' ? 'outlined' : 'text'}
          >
            Week
          </Button>
        </Stack>
      </Grid>
      <MainCard content={false} sx={{mt: 1.5}}>
        <Box sx={{ pt: 1, pr: 2 }}>
          <IncomeAreaChart slot={slot} />
        </Box>
      </MainCard>
    </>
  );
}