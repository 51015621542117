import axios from 'axios';
import Global from '../Helper/Global';

const HandleCreateReview = async (reviewObj) => {
    try {
        const response = await axios.post(
          `${Global}/api/reviews`,
          {data:reviewObj},
        );
        const json = response.data;
        if (json && json.data) {
          return json.data;
        }
      } catch (error) {
        console.error(error);
      }
      return null;
}

export default HandleCreateReview