import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { AuthProvider } from 'react-auth-kit'
import ErrorBoundary from './components/ErrorBoudary';
import Global from './Helper/Global';

const client = new ApolloClient({
  uri: `${Global}/graphql`,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
        <AuthProvider authType = {'cookie'}
                  authName={'_auth'}
                  cookieDomain={window.location.hostname}
                  cookieSecure={true}>
    <HashRouter>
      <ApolloProvider client={client}>
        <ErrorBoundary>
        <App />
        </ErrorBoundary>
      </ApolloProvider>
    </HashRouter>
    </AuthProvider>
  </React.StrictMode>
);


