import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import GradientCover from "../components/GradientCard";
import Jumbotron from "../components/Jumbotron";
import Footer from "../components/Footer";
import { useQuery, gql } from '@apollo/client';
import { useNavigate } from "react-router-dom";
import '../App.css';
import './Home.css'

import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularIndeterminate from "../components/Loading";
import Notification from "../dashboard/Notification";


const GET_LODGES = gql`
query{
  lodges{
    data{
      id
      attributes{
        name
        rating
        Pricing
        contact_details{
          location{
            village
            province
            city
            town
          }
        }
        main{
          data{
            attributes{
              name
              url
              blurhash
            }
          }
        }
      }
    }
  }
}
`;
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function Home() {
  const { loading, error, data = {} } = useQuery(GET_LODGES);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:1200px)");

  if (loading) return <CircularIndeterminate/>
  if (error) return <p>Error : {error.message}</p>;
  return (
    <>
    {/* <Notification /> */}
      <Container sx={{ mb: 20 }} fixed disableGutters={true}>
        <ToastContainer />

        <Box sx={{ mt: 9 }}>
          <div className="input-wrapper">
            <SearchIcon sx={{ color: "royalblue" }} />
            <input placeholder="Search…" onChange={(event) => { setSearchTerm(event.target.value); }} />
          </div>
        </Box >
        <Box sx={{ mt: 2 }}>
          {/* <Jumbotron /> */}
        </Box >
        <Grid margin={matches ? "-50px auto auto" : "auto"} sx={{width:"calc(100% - 8px)"}} container spacing={6} mt={1}>
          {data.lodges.data
            .filter((lodge) => {
              if (searchTerm === "") {
                return lodge;
              }
              else if (lodge?.attributes.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                lodge?.attributes.contact_details.location.city?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                lodge?.attributes.contact_details.location.province?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                lodge?.attributes.contact_details.location.town?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                lodge?.attributes.contact_details.location.village?.toLowerCase().includes(searchTerm.toLowerCase())) {
                return lodge;
              }
            }).map((lodge) => (
              <GradientCover key={lodge.id} lodge={lodge} />
              // <div className="wrapper" onclick={()=>{navigate('promos')}}></div>
            ))}
        </Grid>

      </Container>
      <Footer />
    </>
  );
}