import * as React from 'react';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { Grid, Rating } from '@mui/material';
import { useNavigate } from "react-router-dom";
import ImageComponent from './ImageComponent';

export default function GradientCover({ lodge }) {

  const navigate = useNavigate();
  return (
    <Grid onClick={() => { navigate(`/lodge/${lodge.id}`) }} item lg={3} xs={10} sm={6} md={4} >
      <Card variant="outlined"
        sx={(theme) => ({
          minHeight: '280px',
          flexGrow: 1,
          flexWrap: 'wrap',
          overflow: 'hidden',
          gap: 'clamp(0px, (100% - 360px + 32px) * 999, 16px)',
          transition: 'transform 0.3s, border 0.3s',
          '&:hover': {
            cursor: "pointer",
            transform: 'translateY(-5px)',
          },
          '& > *': { minWidth: 'clamp(0px, (360px - 100%) * 999,100%)' },
        })}>
        <CardCover >
          <ImageComponent url={lodge.attributes.main.data.attributes.url} hash={lodge.attributes.main.data.attributes.blurhash} />
          {/* <ImageComponent url={`http://localhost:1337${lodge.attributes.main.data.attributes.url}`} hash={lodge.attributes.main.data.attributes.blurhash} /> */}
        </CardCover>
        <CardCover
          sx={{
            background:
              'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
          }}
        />
        <CardContent sx={{ justifyContent: 'flex-end' }}>

          <Typography variant="h6" component="h2" marginTop={0} textColor="#fff">
            From R{lodge.attributes.Pricing}
          </Typography>
          <Typography level="h2" fontSize="lg" textColor="#fff" mb={1}>
            {lodge.attributes.name}
          </Typography>
          <Rating
            name="size-small"
            size="small"
            defaultValue={lodge.attributes.rating}
            precision={0.25}
            readOnly
          />
          <Typography
            startDecorator={<LocationOnRoundedIcon />}
            textColor="neutral.300"
          >
            {lodge.attributes.contact_details.location.village}
          </Typography>
        </CardContent>
      </Card>
    </Grid>

  );
}