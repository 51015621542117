import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import dayjs from 'dayjs';

export default function DateTimePickerViewRenderers({ value, handleDate }) {
  // Use dayjs to get the current date and time
  const today = dayjs();
  const currentTime = dayjs().toDate();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
        <DateTimePicker
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          label="Select Arrival time"
          value={value}
          onChange={handleDate}
          minTime
          minDate={today}  // Set the minimum selectable date to today
          minTime={today}  // Set the minimum selectable time to the current time
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
