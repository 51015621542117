import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import DateTimePickerViewRenderers from "./DateTimePicker";
import BasicSelect from "./SelectNumberOfRooms";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ numOfRooms, handleChange, value, handleDate, handleCreateBooking, disable }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(disable)
  };
  const handleClose = () => setOpen(false);
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false);
  const timer = React.useRef();

  const handleLoading = () => {
    if (!loading) {
      setLoading(true);
      timer.current = window.setTimeout(() => {
        navigate('/mybookings')
      }, 1000);
    }

  };
  const notify = () => toast("Successfully Booked please pay at Lodge/Guest House");

  return (
    <div>
      <Button

        onClick={handleOpen}>Book now</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <BasicSelect numOfRooms={numOfRooms} handleChange={handleChange} />
          <DateTimePickerViewRenderers value={value} handleDate={handleDate} />
          <Button
            variant="solid"
            size="sm"
            color="primary"
            aria-label="Explore Bahamas Islands"
            sx={{ ml: 'auto', fontWeight: 600 }}
            onClick={() => {
              handleCreateBooking()
              handleLoading()
              notify()
            }}

          >
            Confirm Booking</Button>
          {loading && (
            <CircularProgress
              size={28}
              sx={{
                color: 'green',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: 6,
                marginLeft: 7,
              }}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
}