import React, { useEffect, useState } from 'react'
import { Blurhash } from 'react-blurhash'

function ImageComponent({ url, hash }) {
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.onload = () => {
            setImageLoaded(true);
        }
        img.src = url
    }, [url])

    return (
        <>
            <div style={{ display:imageLoaded ? 'none' : 'inline' }}>
                <Blurhash
                    hash={hash}
                    resolutionX={32}
                    resolutionY={32}
                    punch={1}
                    width={"100%"}
                    height={"100%"}
                />
            </div>
            <img
            width={"100%"}
            height={"100%"}
                
                src={url}
                // src={`${lodge.attributes.main.data.attributes.url}`}
                //srcSet="https://images.unsplash.com/photo-1542773998-9325f0a098d7?auto=format&fit=crop&w=320&dpr=2 2x"
                loading="lazy"
                alt=""
                style={{ display:!imageLoaded ? 'none' : 'inline' }}
            />
        </>

    )
}

export default ImageComponent