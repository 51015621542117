import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FaMinus, FaPlus } from "react-icons/fa";
import axios from "axios";
import RefreshIcon from '@mui/icons-material/Refresh';
import AlertVariousStates from "../components/Alert";
import SnackbarWithDecorators from "../components/SnackBar";
import Global from "../Helper/Global";

function NumberOfRoom({ numOfRooms, roomid }) {
    const [count, setCount] = useState(numOfRooms);
    const refresh = () => window.location.reload(true);

    function handleClick() {
        setCount(count + 1);
    }
    
    function handleDecrease() {
        if (count > 0) {
            setCount(count - 1);
        }
    }
    
    function handleUpdate() {
        try {
            let data = {};
            data['numOfRooms'] = count;
            axios.put(`${Global}/api/room-types/${roomid}`, { data: data });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Box sx={{ mt: 0 }}>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Button size="small"
                    style={{ marginBottom: 50, marginLeft: 5 }}
                    onClick={handleClick}>
                    <FaPlus />
                </Button>
                <Typography style={{ marginBottom: 50, marginLeft: 13 }}>
                    {count}
                </Typography>
                <Button size="small"
                    style={{ marginBottom: 50, marginLeft: 13 }}
                    onClick={handleDecrease}>
                    <FaMinus />
                </Button>
            </Grid>
            <Box align='center'>
                <Button onClick={handleUpdate} sx={{ mt: 1 }}>
                    <SnackbarWithDecorators name={"Update"} message={"Updated successfully"} />
                </Button>
            </Box>
            <Box sx={{ mt: 2 }} align='center'>
                <Button onClick={refresh} sx={{ mt: 1 }}>
                    <SnackbarWithDecorators name={"Refresh"} message={"Page and data modified"} />
                </Button>
            </Box>
            <Box sx={{ mt: 2 }} align='center'>
                <AlertVariousStates content={"Note: refresh data"} />
            </Box>
        </Box>
    );
}

export default NumberOfRoom;
