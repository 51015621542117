import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Tooltip from '@mui/material/Tooltip';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Button, Container } from '@mui/material';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import { useNavigate } from "react-router-dom";
import SelectField from './SelectField';
import { useSignOut } from 'react-auth-kit';
import { getUserid } from '../Helper/Helper';
import LoginIcon from '@mui/icons-material/Login';
import logo from '../Assets/LogoGuest.png'
import '../App.css'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  // backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default function PrimarySearchAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const signOut = useSignOut()
  const navigate = useNavigate();
  const userId = getUserid();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => {
        handleMenuClose()
        navigate("/profile")
      }}>Profile</MenuItem>
      <MenuItem onClick={() => {
        handleMenuClose()
        signOut()
        navigate("/")
      }}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => { navigate("/promos") }}>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
            <LoyaltyIcon />
        </IconButton>
        <Typography sx={{ m: 0 }}>Promotions</Typography>
      </MenuItem>
      <MenuItem onClick={() => { navigate("/events"); }}>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
            <EventAvailableIcon />
        </IconButton>
        <Typography sx={{ m: 0 }}>Events</Typography>
      </MenuItem>
      {
        userId
          ? <Box>
            <MenuItem onClick={() => { navigate("/mybookings"); }}>
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
              >
                  <EventSeatIcon />
              </IconButton>
              <Typography sx={{ m: 0 }}>Bookings</Typography>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Typography sx={{ m: 0 }}>Profile</Typography>
            </MenuItem>
          </Box>
          :
          <Box>
            <MenuItem onClick={() => { navigate("/login"); }}>
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
              >
                  <LoginIcon />
              </IconButton>
              <Typography sx={{ m: 0 }}>Login</Typography>
            </MenuItem>
          </Box>
      }
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ background: "#063970"}}>
        <Container fixed disableGutters={true}>
          <Toolbar>
          
            <Box
              onClick={() => { navigate("/"); }}
              component="img"
              sx={{
                height: 64,
                width: 66,
                cursor: 'pointer'
              }}
              alt="Your logo."
              src={logo}
            />

            <p
              className='font-bau'
            >
              GUEST LOCATIONS
            </p>

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Tooltip title='Promotions'>
                <IconButton onClick={() => { navigate("/promos"); }} size="large" aria-label="show 4 new mails" color="inherit">
                    <LoyaltyIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Events'>
                <IconButton onClick={() => { navigate("/events"); }} size="large" aria-label="show 4 new mails" color="inherit">
                    <EventAvailableIcon />
                </IconButton>
              </Tooltip>
              {userId
                ? <Box><Tooltip title='Bookings'>
                  <IconButton
                    onClick={() => { navigate("/mybookings"); }}
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                  >
                      <EventSeatIcon />
                  </IconButton>
                </Tooltip>
                  <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton></Box>
                : <Button color='inherit' variant='solid' sx={{ borderRadius: 3, background: 'black', ml: 1 }} onClick={() => navigate('/login')}>Login</Button>
              }
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}