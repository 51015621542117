import React, { useEffect } from 'react'
import { useIsAuthenticated } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';

export default function PrivateRoute( {children}) {

const isAuthenticated = useIsAuthenticated()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/login")
    }
  },[]);

  return (
    children
  )
}
