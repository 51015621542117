import React, { useEffect, useState } from "react";
import { Box, Container, Chip, useMediaQuery, useTheme } from "@mui/material";
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Typography from '@mui/joy/Typography';
import dateFormat from "dateformat";
import axios from "axios";
import Global from "../Helper/Global";

export const Booking = ({ obj }) => {
  const [data, setData] = useState('');
  const [bookingResult, setBookingResult] = useState({
    color: '',
    response: ''
  });

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (obj.isBookingFullfilled === '1') {
      setBookingResult({ color: 'green', response: 'Settled' });
    } else if (obj.isBookingFullfilled === '2') {
      setBookingResult({ color: 'red', response: 'Cancelled' });
    } else if (obj.isBookingFullfilled === '3') {
      setBookingResult({ color: 'orange', response: 'Deposited' });
    } else if (obj.isBookingFullfilled === '4') {
      setBookingResult({ color: 'green', response: 'Fully Paid' });
    } else {
      setBookingResult({ color: 'primary', response: 'Reserved' });
    }

    axios.get(`${Global}/api/lodges/${obj.lodge.data.id}?populate=main`)
      .then(res => {
        setData(res.data.data.attributes.main.data.attributes.formats.small.url);
      })
      .catch(err => {
        console.log(err);
      });
  }, [obj]);

  return (
    <Container disableGutters={true} sx={{ mt: 2 }}>
      <Card
        orientation={isSmallScreen ? "vertical" : "horizontal"}
        variant="outlined"
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row',
          alignItems: isSmallScreen ? 'flex-start' : 'center',
          p: 2,
          boxShadow: 3,
          borderRadius: 2,
          backgroundColor: '#f5f5f5',
        }}
      >
        <CardOverflow sx={{ flex: '0 0 auto', mb: isSmallScreen ? 2 : 0 }}>
          <AspectRatio ratio="1" sx={{ width: isSmallScreen ? '100%' : 135 }}>
            <Box
              component="img"
              sx={{ width: isSmallScreen ? '100%' : 135, height: isSmallScreen ? 'auto' : 200 }}
              src={data}
              loading="eager"
              alt=""
            />
          </AspectRatio>
        </CardOverflow>
        <CardContent sx={{ flex: '1 1 auto', ml: isSmallScreen ? 0 : 2, mb: isSmallScreen ? 2 : 0 }}>
          <Typography fontWeight="md" variant="h6" sx={{ color: '#333' }}>
            {obj.lodge.data.attributes.name}
          </Typography>
          <Typography sx={{ mt: 1, color: '#777' }}>
            {dateFormat(obj.date_time, "dddd, mmmm dS, yyyy, h:MM TT")}
          </Typography>
        </CardContent>
        <CardContent sx={{ flex: '1 1 auto', mb: isSmallScreen ? 2 : 0 }}>
          <Typography fontWeight="md" variant="body1" sx={{ color: '#333' }}>
            {obj.roomType}
          </Typography>
          <Typography fontWeight="md" variant="body1" sx={{ mt: 1, color: '#777' }}>
            R{obj.price}
          </Typography>
          <Chip
            label="Cash on Arrival"
            color="primary"
            variant="outlined"
            sx={{ mt: 1 }}
          />
        </CardContent>
        <CardOverflow
          variant="soft"
          sx={{
            px: 1,
            writingMode: isSmallScreen ? 'horizontal-tb' : 'vertical-rl',
            textAlign: 'center',
            fontSize: 'small',
            fontWeight: 'bold',
            letterSpacing: '1px',
            textTransform: 'uppercase',
            borderLeft: isSmallScreen ? 'none' : `1px solid ${bookingResult.color}`,
            borderTop: isSmallScreen ? `1px solid ${bookingResult.color}` : 'none',
            color: bookingResult.color,
            mt: isSmallScreen ? 2 : 0,
          }}
        >
          {bookingResult.response}
        </CardOverflow>
      </Card>
    </Container>
  );
};
