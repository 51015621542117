import React from 'react';
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBCol,
} from 'mdb-react-ui-kit';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import FormHelperText from '@mui/material/FormHelperText';
import Global from '../Helper/Global';
import { Button, Typography, TextField } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import logo from '../Assets/BlueLogo.png'; // Assuming you have a logo.png file in your Assets folder

const initialUser = { email: '', password: '', username: '' };

function Register() {
  const [user, setUser] = useState(initialUser);
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const timer = React.useRef();

  const handleLoading = () => {
    if (!loading) {
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  const schema = yup.object().shape({
    password: yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .max(20, 'Password cannot be more than 20 characters'),
    email: yup.string().email('Invalid email format').required('Email is required'),
    username: yup.string().required('Username is required')
      .min(8, 'Username must be at least 8 characters')
      .max(20, 'Username cannot be more than 20 characters'),
  });

  const signUp = async () => {
    try {
      const url = `${Global}/api/auth/local/register`;
      await schema.validate(user);
      if (user.username && user.email && user.password) {
        const res = await axios.post(url, user);
        if (!!res) {
          toast.success('Registered successfully!', { hideProgressBar: true });
          setUser(initialUser);
          navigate('/email-verification');
        }
      }
    } catch (err) {
      if (err && err instanceof yup.ValidationError) setError(err.message);
    }
  };

  const handleUserChange = ({ target }) => {
    const { name, value } = target;
    setUser(currentUser => ({
      ...currentUser,
      [name]: value,
    }));
  };

  return (
    <MDBContainer className="my-0">
      <MDBCard>
        <MDBRow className='g-0'>
        <MDBCol md='5' className="d-flex justify-content-center align-items-center p-1">
            <img 
              src={logo} 
              alt="Logo" 
              style={{ 
                width: '80%', 
                height: 'auto', 
                maxWidth: '80%', 
                '@media (max-width: 768px)': { 
                  maxWidth: '10%' 
                } 
              }} 
            />
          </MDBCol>


          <MDBCol md='6'>
            <MDBCardBody className='d-flex flex-column'>
              <h5 className="fw-normal my-4 pb-3" style={{ letterSpacing: '1px' }}>Register a New Account</h5>

              <TextField
                name="username"
                value={user.username}
                onChange={handleUserChange}
                label='Username'
                type='text'
                size="large"
                placeholder="Enter Your Username e.g John123"
                fullWidth
                margin="normal"
                InputProps={{
                  startAdornment: (
                    <PersonIcon style={{ marginRight: '8px' }} />
                  ),
                }}
              />

              <TextField
                name="email"
                value={user.email}
                onChange={handleUserChange}
                label='Email Address'
                type='email'
                size="large"
                placeholder="Enter your email"
                fullWidth
                margin="normal"
                InputProps={{
                  startAdornment: (
                    <EmailIcon style={{ marginRight: '8px' }} />
                  ),
                }}
              />

              <TextField
                name="password"
                value={user.password}
                onChange={handleUserChange}
                label='Password'
                type='password'
                size="large"
                placeholder="Must be 8 characters long"
                fullWidth
                margin="normal"
                InputProps={{
                  startAdornment: (
                    <LockIcon style={{ marginRight: '8px' }} />
                  ),
                }}
              />

              <FormHelperText sx={{ color: 'red' }}>{error}</FormHelperText>

              <Button
                onClick={() => {
                  signUp();
                  handleLoading();
                }}
                sx={{ borderRadius: 1, color: 'black', marginTop: 2 }}
                variant="outlined"
              >
                {!loading ? 'Sign Up' : 'Loading...'}
              </Button>

              <p className="mb-5 pb-lg-2" style={{ color: '#393f81', marginTop: '15px' }}>
                Already have an account?{' '}
                <Typography
                  sx={{ cursor: 'pointer' }}
                  component="a"
                  onClick={() => navigate('/login')}
                  style={{ color: '#393f81' }}
                >
                  Login Here
                </Typography>
              </p>

              <div className='d-flex flex-row justify-content-start'>
                <a href="/" className="small text-muted me-1">Terms of use.</a>
                <a href="/" className="small text-muted">Privacy policy</a>
              </div>
            </MDBCardBody>
          </MDBCol>
        </MDBRow>
      </MDBCard>
    </MDBContainer>
  );
}

export default Register;
