import React, { useEffect, useState } from 'react'
import Tables from './Tables'
import { Container, Grid, Paper } from '@mui/material'
import Navbar from './Navbar'
import Jumbotron from './Jumbotron'
import Foota from './Foota'
import Roomtype from './Roomtype'
import axios from 'axios'
import { getUserid } from '../Helper/Helper'
import Global from '../Helper/Global'

export default function Dashboard() {

  const [data, setData] = useState([]);
  const [lodgeId,setLodgeId] = useState('')
  const [time,setTime] = useState(30)

  useEffect(() => {
    let id = getUserid()
    const timeout = setTimeout(() => {
       // const id = getLodgeId() 
    axios.get(`${Global}/api/user-infos?populate=user&filters[user][id][$eq]=${id}`)
    .then(res => {
      setLodgeId(res.data.data[0].attributes.lodgeId);
      axios.get(`${Global}/api/bookings?sort=createdAt:desc&populate[user][populate]=user_info&populate=lodge&filters[lodge][id][$eq]=${res.data.data[0].attributes.lodgeId}`)
        .then(res => {
          const arr = Object.values(res.data)
          setData(arr[0])
          setTime(900000)
        })
    })
    .catch(err => {
      console.log(err)
    })
    }, time);
    return () => clearTimeout(timeout);
  }, [data, time])


  return (
    <>
      <Navbar />
      <Container disableGutters={true} fixed maxWidth="lg" sx={{ mt: 12, mb: 4 }}>
        <Grid container spacing={3}>
          {/* {console.log(data)} */}
          <Grid item xs={12} md={8} lg={7}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 500,
              }}
            >
              <Jumbotron />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={5}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 500,
              }}
            >
              <Roomtype lodgeId={lodgeId}/>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', overflowX:'auto'}}>
              <Tables bookings={data} />
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Foota />
    </>
  )
}
