import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import Rating from '@mui/material/Rating';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import { Avatar } from "@mui/joy";
import { Box, Button, Link, Typography } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Global from "../Helper/Global";
import { getUserid } from "../Helper/Helper";

function Review({ lodgeId }) {
  const [reviews, setReviews] = useState([]);
  const navigate = useNavigate();
  const [vissible, setVissible] = useState(3);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const showMoreReviews = () => {
    setVissible((prevValue) => prevValue + 3);
  };

  useEffect(() => {
    axios.get(`${Global}/api/reviews?populate=*&filters[lodge][id][$eq]=${lodgeId}`)
      .then(res => {
        const arr = Object.values(res.data);
        setReviews(arr[0]);
      })
      .catch(err => {
        console.log(err);
      });

    if (getUserid()) {
      setIsLoggedIn(true);
    }
  }, [lodgeId]);

  return (
    <Container fluid className="App text-light text-left">
      <Col md={{ span: 8, offset: 2 }}>
        <Col>
          <Row className="mt-2">
            <Col>
              <Box sx={{ display: 'flex', overflowX: 'scroll' }}>
                {reviews.slice(0, vissible).map((r) => {
                  return (
                    <Card
                      key={r.id}
                      sx={{ mb: 1, minWidth: 300, marginRight: 2 }}
                    >
                      <CardContent orientation="horizontal">
                        <Avatar variant="circle" width={44} height={44} />
                        <div>
                          <Typography sx={{fontFamily: 'Zen Maru Gothic'}} level="body-sm" variant="text" width={200}>
                            {r.attributes.user.data.attributes.username}
                          </Typography>
                        </div>
                        <Rating name="size-small" width={100} value={r.attributes.rating} readOnly />
                      </CardContent>
                      <CardContent sx={{ gap: 0.5, mt: 1, fontFamily: 'Zen Maru Gothic' }}>
                        <p>{r.attributes.comment}</p>
                      </CardContent>
                    </Card>
                  );
                })}
              </Box>
              <Box>
                <Button sx={{ width: '100%' }} onClick={showMoreReviews}>Load more</Button>
              </Box>

              {isLoggedIn && (
                <Link
                  sx={{ width: '100%' }}
                  component="button"
                  variant="body2"
                  onClick={() => {
                    navigate(`/comment/${lodgeId}`);
                  }}
                >
                  Click here for a comment or compliment
                </Link>
              )}
            </Col>
          </Row>
        </Col>
      </Col>
    </Container>
  );
}

export default Review;
