import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import CreateBooking from './CreateBooking';
import { useNavigate, useParams } from 'react-router-dom';
import { getUserid } from '../Helper/Helper';
import BasicModal from './Modal';
import PoolIcons from '@mui/icons-material/Pool';
import { Button, Chip } from '@mui/material';
import Global from '../Helper/Global';
import axios from 'axios';

export default function RoomCard({ room }) {
  const [pool, setPool] = React.useState(true);
  const [numOfRooms, setNumOfRooms] = React.useState('');
  const handleChange = (event) => setNumOfRooms(event.target.value);
  const [roomTypeAvail, setRoomTypeAvail] = React.useState({ title: 'Available', color: 'green' });
  const [disable, setDisable] = React.useState(true);
  const navigate = useNavigate();
  const [userinfo, setUserInfo] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const handleDate = (newValue) => setValue(newValue);

  let userid = getUserid();

  React.useEffect(() => {
    const data = axios.get(`${Global}/api/users/${userid}?populate=user_info`).then((res) => { if (res.data.user_info) { setUserInfo(true); } });

    if (userid && userinfo === false) {
      setDisable(false);
      navigate('#/createprofile');
    } else {
      if (disable === false) { setDisable(true); }
      if (room?.numOfRooms === 1) {
        setRoomTypeAvail({ title: '1 room available!!!', color: 'orange' });
      } else if (room?.numOfRooms < 1) {
        setRoomTypeAvail({ title: 'Fully booked', color: 'Red' });
        setDisable(false);
      }
    }
  }, [userinfo]);

  //lodge id
  const { id } = useParams();

  let finalPrice = parseInt(room?.price) * parseInt(numOfRooms);

  const handleCreateBooking = () => {
    const data = {
      lodge: id,
      room_type: room?.type,
      num_of_rooms: numOfRooms,
      user: userid,
      date_time: value,
      roomType: room?.type,
      price: finalPrice.toString(),
    };
    CreateBooking(data);
  };

  return (
    <Card variant="outlined" sx={{ width: 320, mt:2 }}>
      <Typography level="h2" fontSize="md" sx={{ mb: 0.5 }}>
        {room?.type}
      </Typography>
      <Chip
        label={roomTypeAvail.title}
        sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem', backgroundColor: roomTypeAvail.color }}
      />
      <AspectRatio minHeight="120px" maxHeight="200px" sx={{ my: 2 }}>
        <img
          src={`${room?.image.data.attributes.url}`}
          loading="lazy"
          alt=""
        />
      </AspectRatio>
      <Box sx={{ display: 'flex' }}>
        <div>
          <Typography level="body3">Total price:</Typography>
          <Typography fontSize="lg" fontWeight="lg">
            R{room?.price}
          </Typography>
        </div>
        <Box
          variant="solid"
          size="sm"
          color="primary"
          aria-label="Explore room"
          sx={{ ml: 'auto', fontWeight: 600 }}
        >
          {userid
            ? <BasicModal disable={disable} value={value} handleChange={handleChange} handleDate={handleDate} numOfRooms={numOfRooms} handleCreateBooking={handleCreateBooking} />
            : <Button onClick={() => { navigate('/login'); }}>BOOK NOW</Button>
          }
        </Box>
      </Box>
    </Card>
  );
}

