import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import HouseboatIcon from '@mui/icons-material/Houseboat';
import { Container } from '@mui/material';
import logo from '../Assets/LogoGuest.png'
import '../App.css'
import { useSignOut } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';

export default function Navbar() {
  const signOut = useSignOut()
  const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{background:"#063970"}}>
        <Container disableGutters={true} fixed >
        <Toolbar disableGutters={true}>
        <Box
              component="img"
              sx={{
                height: 64,
                width: 66,
                cursor: 'pointer'
              }}
              alt="Your logo."
              src={logo}
            />

            <p
              className='font-bau'
            >
              GUEST LOCATIONS
            </p>
          <Button onClick={()=>{
            signOut()
            navigate("/login")
            }} sx={{ml:'auto', mr:0}} color="inherit">logout</Button>
        </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}