import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import { Container, Typography, Box, Paper } from '@mui/material';
import { Booking } from '../components/Booking';
import { useIsAuthenticated } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';
import { getUserid } from '../Helper/Helper';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AlertVariousStates from '../components/Alert';
import Global from '../Helper/Global';

export const BookingsWrapper = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const id = getUserid();
    axios
      .get(`${Global}/api/bookings?sort=createdAt:desc&populate=*&filters[user][id][$eq]=${id}`)
      .then((res) => {
        const arr = Object.values(res.data);
        setData(arr[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Container disableGutters={true} sx={{ mt: 9 }}>
      <ToastContainer />
      <Typography
        variant="h4"
        component="h1"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 5,
          fontWeight: 'bold',
          color: '#333',
          textTransform: 'uppercase',
        }}
      >
        My Bookings
      </Typography>
      <Tabs aria-label="tabs" defaultValue={0} sx={{ mt: 3 }}>
        <TabList
          variant="plain"
          sx={{
            '--List-padding': '0px',
            '--List-radius': '0px',
            '--ListItem-minHeight': '48px',
            [`& .${tabClasses.root}`]: {
              boxShadow: 'none',
              fontWeight: 'medium',
              color: '#555',
              [`&.${tabClasses.selected}`]: {
                color: '#1976d2',
                fontWeight: 'bold',
              },
              [`&.${tabClasses.selected}::before`]: {
                content: '""',
                display: 'block',
                position: 'absolute',
                left: '0',
                right: '0',
                bottom: 0,
                height: 3,
                bgcolor: '#1976d2',
              },
            },
          }}
        >
          <Tab>MY BOOKINGS</Tab>
          {/* <Tab>Previous Bookings</Tab> */}
        </TabList>
        <AlertVariousStates content={"Please note that your reservation status is pending. For bookings scheduled for later than today, your booking may be canceled if the deposit is not paid within 24 hours."} />
        <TabPanel value={0} sx={{ p: '16px' }}>
          {data.length >= 1 ? (
            data.map((booking) => (
              <Box key={booking.id} sx={{ mb: 2 }}>
                <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                  <Booking key={booking.id} obj={booking.attributes} />
                </Paper>
              </Box>
            ))
          ) : (
            <Typography variant="body1" sx={{ textAlign: 'center', mt: 2 }}>
              No bookings found.
            </Typography>
          )}
        </TabPanel>
        {/* <TabPanel value={1} sx={{ p: 2 }}>
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            A List of old bookings
          </Typography>
        </TabPanel> */}
      </Tabs>
    </Container>
  );
};

