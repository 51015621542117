import Title from './Title';
import Button from '@mui/joy/Button';
import NumberOfRoom from './NumberOfRoom';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Global from '../Helper/Global';
import PropTypes from 'prop-types';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Roomtype({ lodgeId }) {
  const [type, setType] = useState('');
  const [data, setData] = useState([]);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  

  useEffect(() => {
    axios.get(`${Global}/api/room-types?filters[lodges][id][$eq]=${lodgeId}`)
      .then(res => {
        const arr = Object.values(res.data)
        setData(arr[0])
      })
      .catch(err => {
        console.log(err.message)
      })
  }, [lodgeId])

  return (
    <>
    <Box
        display="flex"
        justifyContent="center">
        <Title>Rooms</Title>
      </Box>
    <Box sx={{width:"100%"}}>
    <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        {data.map((room,index) => <Tab key={room.id} label={room.attributes.type} {...a11yProps(index)}/>)}
      </Tabs>
      {
        data.map((room,index) => <TabPanel key={index} value={value} index={index}><NumberOfRoom roomid={room.id} numOfRooms={room.attributes.numOfRooms}/></TabPanel>)
      }
    </Box>
    {/* <Tabs  variant="scrollable"
        scrollButtons="auto" aria-label="Basic tabs" defaultValue={0}>
      <TabList>
          {data.map((room) => <Tab key={room.id}>{room.attributes.type}</Tab>)}
      </TabList >
      {
        data.map((room,index) => <TabPanel key={index} value={index}><NumberOfRoom roomid={room.id} numOfRooms={room.attributes.numOfRooms}/></TabPanel>)
      }
    </Tabs> */}
    </Box>
    </>

  );
}