import React, { useRef } from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBInput
}
from 'mdb-react-ui-kit';
import axios, { AxiosError } from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import FormHelperText from '@mui/material/FormHelperText';
import Global from '../Helper/Global';
import AlertVariousStates from '../components/Alert';
import { Button, Typography } from '@mui/material';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const [error, setError] = useState();
    const schema = yup.object().shape({
      email: yup.string().email('invalid email format').required("Email is required"),
    });

    const [loading, setLoading] = React.useState(false);
    const timer = React.useRef();
  
    const handleLoading = () => {
      if (!loading) {
        setLoading(true);
        timer.current = window.setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
  
    };

    const handleSubmit = async ()=>{
      setError("");
      const obj = {email:email}
      // console.log(obj)
        try {
          await schema.validate(obj);
          axios.post(`${Global}/api/auth/forgot-password`, {
            email: email, // user's email
        })
        .then(response => {
            setError('Reset link sent! to your email Please wait... as we Redirect you to login');
            // navigate('/login')
            timer.current = window.setTimeout(() => {
              navigate('/login')
            }, 1500);
        })
        .catch(error => {
            setError('An error occurred:', error.response);
        });
        } catch (err) {
          if (err && err instanceof AxiosError) {setError("Incorrect Email or Password"); console.log(err)}
          else if (err && err instanceof yup.ValidationError) setError(err.message);
        }
    }
  return (
    <MDBContainer className="my-0">

      <MDBCard>
        <MDBRow className='g-0'>

          <MDBCol md='3'>
            <MDBCardImage src='https://images.pexels.com/photos/2791028/pexels-photo-2791028.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' alt="login form" className='rounded-start w-100'/>
          </MDBCol>
          <MDBCol md='5'>
            <MDBCardBody className='d-flex flex-column'>

              <div className='d-flex flex-row mt-2'>
                <MDBIcon fas icon="cubes fa-3x me-3" style={{ color: '#ff6219' }}/>
                <span className="h1 fw-bold mb-0">Forgot Password</span>
              </div>
              <br/>
              <MDBInput onChange={e => setEmail(e.target.value)} label='Email address' size="lg"/>
              {/* <Typography>{error}</Typography> */}
              <FormHelperText sx={{ color: 'red' }}>{error}</FormHelperText>
              <Button onClick={() => {
                  handleSubmit()
                  handleLoading()
                }} sx={{ borderRadius: 1, color: 'black' }} type='submit' variant="outlined">
                  {!loading ? (
                    "submit"
                  ) : (
                    'Loading.....'
                  )}
                </Button>
            </MDBCardBody>
          </MDBCol>
        </MDBRow>
      </MDBCard>
    </MDBContainer>
  );
}

export default ForgotPassword;