import Login from './pages/Login';
import { Profile } from './pages/Profile';
import Register from './pages/Register';
import { Route, Routes } from 'react-router-dom';

import Appbar from './components/Appbar';
// import Card from './components/Tour'
import Home from './pages/Home';
import Lodge from './pages/Lodge'
// import Userfront from "@userfront/react";
import Promos from './pages/Promos';
import Events from './pages/Events';
import { BookingsWrapper } from './pages/BookingsWrapper';

import { RequireAuth } from "react-auth-kit";
import { useState } from 'react';
import PrivateRoute from './PrivateRoute';
import CreateProfile from './pages/CreateProfile';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Dashboard from './dashboard/Dashboard';
import CreateReview from './pages/CreateReview';
import NotFound from './pages/NotFound';
import { Helmet } from 'react-helmet';
import EmailVerificationPage from './pages/EmailVerificationPage';

// Userfront.init("xbpg98dn");

// const PasswordResetForm = Userfront.build({
//   toolId: "ramankk"
// });


function App() {

  return (

    <div>
      <Helmet>
        <title>Best Hammanskraal Accommodations - Comfortable and Affordable BnB Options</title>
        <meta name="description" content="Discover the best accommodations in Hammanskraal. Stay at our top-rated BnBs and guest houses. Book your stay online today!" />
        <meta name="keywords" content="Hammanskraal accommodations, BnB Hammanskraal, guest houses Hammanskraal" />
      </Helmet>
        <Appbar />
        <Routes>
          <Route index element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/comment/:lodgeId' element={<CreateReview />} />
          <Route path='/createprofile' element={<CreateProfile />} />
          <Route path="/email-verification" element={<EmailVerificationPage />} />
          <Route path='/signup' element={<Register />} />
          <Route path='/profile' element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route path='/promos' element={
            <PrivateRoute>
              <Promos />
            </PrivateRoute>
          } />
          <Route path='/events' element={<Events />} />
          <Route path='/mybookings' element={<PrivateRoute><BookingsWrapper /></PrivateRoute>} />
          <Route path='/lodge/:id' element={<Lodge />} />
          <Route path='/dashboard' element={<PrivateRoute><Dashboard /></PrivateRoute>} />



          <Route path='/reset-password' element={<ResetPassword />} />
          {/* <Route path='/card' element={<Card />} /> */}
          <Route path='/forgotpassword' element={<ForgotPassword />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
        {/* <PasswordResetForm /> */}
    </div>
  );
}

export default App;
