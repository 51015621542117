import React from 'react'
import ComingSoon from '../components/ComingSoon'

const Events = () => {
  return (
      <>
      <ComingSoon type={"Events"}/>
      </>
  )
}

export default Events