import axios from 'axios';
import Global from '../Helper/Global';

function formatPhoneNumber(phoneNumber) {
  // Check if the phone number starts with '0'
  if (phoneNumber.startsWith('0')) {
    // Remove the leading '0' and concatenate '+27'
    return '+27' + phoneNumber.slice(1);
  }
  // If the phone number doesn't start with '0', return it as is   
  return phoneNumber;
}

const CreateBooking = async (bookingObj) => {
  console.log(bookingObj.lodge);
  try {
    const whatAppNumber = await axios.get(`${Global}/api/user-infos?filters[lodgeId][$eq]=${bookingObj.lodge}`);
    // console.log(whatAppNumber.data.data); // this is an array so I need to map through it and send WhatsApp messages in the loop
    whatAppNumber.data.data.map(async (numbers) => {
      // console.log(formatPhoneNumber(numbers.attributes.phoneNumber));
      try {
        const res = await axios.post('https://twilioservice-1.onrender.com/send-whatsapp', { toNumber: formatPhoneNumber(numbers.attributes.phoneNumber) });
        // console.log('WhatsApp message sent:', res.data);
      } catch (error) {
        console.error('Error sending WhatsApp message:', error);
      }
    });
    const response = await axios.post(`${Global}/api/bookings`, { data: bookingObj });
    const json = response.data;
    if (json && json.data) {
      return json.data;
    }
  } catch (error) {
    console.error('Error creating booking:', error);
  }
  return null;
}

export default CreateBooking;
