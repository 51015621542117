import React, { useEffect } from 'react'
import ComingSoon from '../components/ComingSoon'
import {useIsAuthenticated} from 'react-auth-kit';
import { useNavigate } from "react-router-dom";

const Promos = () => {
  const isAuthenticated = useIsAuthenticated()
  const navigate = useNavigate()

  useEffect(() => {
    if(!isAuthenticated()){
      navigate("/login")
  }
  }, []);
  
  return (
      <>
      <ComingSoon type={"Promotions"}/>
      </>
  )
}

export default Promos