import React from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBInput
}
  from 'mdb-react-ui-kit';
import axios, { AxiosError } from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserid } from '../Helper/Helper';
import * as yup from "yup";
import FormHelperText from '@mui/material/FormHelperText';
import Global from '../Helper/Global';
import AlertVariousStates from '../components/Alert';
import { Button } from '@mui/material';


const initialUser = { firstName: "", lastName: "", phoneNumber: "", identityNumber: "" };

function CreateProfile() {
  const [user, setUser] = useState(initialUser);
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [loading, setLoading] = React.useState(false);
  const timer = React.useRef();

  const handleLoading = () => {
    if (!loading) {
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setLoading(false);
      }, 2000);
    }

  };

  const schema = yup.object().shape({
    identityNumber: yup.string().required('ID number is required')
      .matches(/^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))$/, 'Invalid ID number'),
    phoneNumber: yup.string()
      .required("Phone number is required")
      .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Invalid phone number')
      .min(10, "Phone number provided is too short")
      .max(10, "Phone number provided is too long"),
    lastName: yup.string().required('last name is required'),
    firstName: yup.string().required('First name is required'),
  });

  const signUp = async () => {
    try {
      await schema.validate(user);
      let id = getUserid()
      const url = `${Global}/api/user-infos`;
      user.user = id;

      if (user.firstName && user.lastName && user.phoneNumber && user.identityNumber) {
        const res = await axios.post(url, { data: user });
        if (!!res) {
          toast.success("Registered successfully!", {
            hideProgressBar: true,
          });
          setUser(initialUser);
          navigate("/");
        }
      }
    } catch (err) {
      if (err && err instanceof yup.ValidationError) setError(err.message);
    }
  };

  const handleUserChange = ({ target }) => {
    const { name, value } = target;
    setUser((currentUser) => ({
      ...currentUser,
      [name]: value,
    }));
  };

  return (
    <MDBContainer className="my-0">

      <MDBCard>
        <MDBRow className='g-0'>

          <MDBCol md='5'>
            <MDBCardImage src='https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp' alt="profile" className='rounded-start w-100' />
          </MDBCol>

          <MDBCol md='6'>
            <MDBCardBody className='d-flex flex-column'>

              <div className='d-flex flex-row mt-2'>
                <MDBIcon fas icon="cubes fa-3x me-3" style={{ color: '#ff6219' }} />
                <span className="h1 fw-bold mb-0">CREATE PROFILE</span>
              </div>

              <MDBInput
                name="firstName"
                value={user.firstName}
                onChange={handleUserChange} wrapperClass='mb-4' label='Firstname' type='input' size="lg" />

              <MDBInput
                name="lastName"
                value={user.lastName}
                onChange={handleUserChange} wrapperClass='mb-4' label='Lastname' type='input' size="lg" />


              <MDBInput name="phoneNumber"
                value={user.phoneNumber}
                onChange={handleUserChange}
                wrapperClass='mb-4' label='Phone Number' type='input' size="lg" />

              <MDBInput
                name="identityNumber"
                value={user.identityNumber}
                onChange={handleUserChange} wrapperClass='mb-4' label='Identity Number' type='input' size="lg" />
              <FormHelperText sx={{ color: 'red' }}>{error}</FormHelperText>
              {/* <MDBBtn className="mb-4 px-5" color='dark' size='lg' onClick={signUp}>Proceed</MDBBtn> */}
              <Button onClick={() => {
                signUp()
                handleLoading()
              }} sx={{ borderRadius: 1, color: 'black' }} type='submit' variant="outlined">
                {!loading ? (
                  "Proceed"
                ) : (
                  'Loading.....'
                )}
              </Button>

            </MDBCardBody>
          </MDBCol>

        </MDBRow>
        <AlertVariousStates content={"Note that your personal data is encrypted and will not be shared with third party entities"} />
      </MDBCard>


    </MDBContainer>
  );
}

export default CreateProfile;