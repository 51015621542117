import React, { useState } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import Textarea from '@mui/joy/Textarea';
import Rating from '@mui/material/Rating';
import { Box, Typography } from "@mui/material";
import Appbar from '../components/Appbar';
import Footer from "../components/Footer";
import HandleCreateReview from "../components/HandleCreateReview";
import { useParams } from "react-router-dom";
import { getUserid } from "../Helper/Helper";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';

function CreateReview() {
  const [clicked, setClicked] = useState(false);
  const [stars, setStars] = useState(1);
  const [review, setReview] = useState("");
  const [reviews, setReviews] = useState([]);
  const [value, setValue] = React.useState(0);
  const { lodgeId } = useParams()

  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false);
  const timer = React.useRef();

  const handleLoading = () => {
    if (!loading) {
      setLoading(true);
      timer.current = window.setTimeout(() => {
        navigate('/')
      }, 1000);
    }

  };

  let userId = getUserid()

  const resetForm = (e) => {
    e.preventDefault();
    setReview("");
    setStars(1);
    setClicked(false);
  };

  const submitReview = (e) => {
    
    e.preventDefault();
    const newReview = {
      rating: stars,
      comment: review,
      user:userId,
      lodge:lodgeId
    };
    HandleCreateReview(newReview);

    setReviews([...reviews, newReview]);
    resetForm(e);
  };

  return (
    <>
      <Appbar />
      <Typography variant="h5" component="h3" align="center" sx={{ mt: 25 }}>
        Review Your Experience
      </Typography>
      <Box sx={{ mt: 10 }}>
        <Container sx={{ mt: 6 }} fluid className="App text-light text-center">
          <Col md={{ span: 8, offset: 2 }}>
            <Col>
              <Rating
                name="simple-controlled"
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                  setStars(newValue)
                }} />
              <Row >
                <Textarea
                  minRows={2}
                  placeholder="Type in here…"
                  variant="soft"
                  sx={{
                    mb:1,
                    borderBottom: '2px solid',
                    borderColor: 'neutral.outlinedBorder',
                    borderRadius: 0,
                    '&:hover': {
                      borderColor: 'neutral.outlinedHoverBorder',
                    },
                    '&::before': {
                      border: '1px solid var(--Textarea-focusedHighlight)',
                      transform: 'scaleX(0)',
                      left: 0,
                      right: 0,
                      bottom: '-2px',
                      top: 'unset',
                      transition: 'transform .15s cubic-bezier(0.1,0.9,0.2,1)',
                      borderRadius: 0,
                    },
                    '&:focus-within::before': {
                      transform: 'scaleX(1)',
                    },
                  }}
                  onChange={(e) => setReview(e.target.value)}
                />
              </Row>
              <Button sx={{mt:1}}
                variant="success"
                onClick={(e) => {submitReview(e); handleLoading()}}
                disabled={review === ""}
              >
                submit
              </Button>
              {loading && (
            <CircularProgress
              size={28}
              sx={{
                color: 'green',
                position: 'absolute',
                top: '40%',
                left: '50%',
                marginTop: 3,
                marginLeft: 7,
              }}
            />
          )}
            </Col>
          </Col>
        </Container>
      </Box>
      <Footer />
    </>
  );
}

export default CreateReview;