import { Button, Container, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ControlledAccordions from "../components/Accordian";
import QuiltedImageList from "../components/ImageLists";
import { BottomNavigation, Paper } from "@mui/material";
import BasicModal from "../components/Modal";
import { useParams } from "react-router-dom";
import { useQuery, gql } from '@apollo/client';
import RoomCard from "../components/RoomCard";
import Footer from "../components/Footer";


import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Divider from '@mui/material/Divider';
import CircularIndeterminate from "../components/Loading";
import Global from "../Helper/Global";

const GET_LODGE = gql`
  query GetLodge($id:ID!){
    lodge(id:$id){
      data{
        attributes{
          room_types{data{id attributes{type price numOfRooms image{data{attributes{url}}}}}}
          name
          mapSrc
          description
          contact_details{phone_numbers email_address location_description}
          offers{Pool Food wifi toilet fridge shower bathtub beverages double_bed single_bed}
          main{
            data{
              attributes{
                url
              }
            }
          }
          images{
            data{
              id
              attributes{
                url
                name
                blurhash
              }
            }
          }
        }
      }
    }
  }
`;

export default function Tour() {
  const [value, setValue] = React.useState(0);
  const { id } = useParams()
  const { loading, error, data } = useQuery(GET_LODGE, {
    variables: { id: id },
  });
  const matches = useMediaQuery("(min-width:1200px)");


  if (loading) return <CircularIndeterminate />;
  if (error) return <p>Error : {error.message}</p>;

  return (
    <>
      <Container sx={{ mt: 9 }} margin={matches ? "-50px auto auto" : "auto"} fixed>
        <Typography sx={{ fontFamily: 'Arabella' }} variant="h3" component="h1" marginTop={3}>
          {data.lodge.data.attributes.name}
        </Typography>
        <Box marginTop={3} sx={{ display: "flex" }}>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <img
              src={data.lodge.data.attributes.main.data.attributes.url}
              height={400}
              width={600}
              alt=''
              loading="lazy"
            /></Box>

          <QuiltedImageList itemData={data.lodge.data.attributes.images.data} />
        </Box>
        <Typography sx={{ fontFamily: 'Katibeh' }} variant="h5" component="h3" marginTop={1}>
          About lodge
        </Typography>
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ fontFamily: 'Zen Maru Gothic' }} whiteSpace="pre-wrap" variant="paragraph" component="p" marginTop={1}>
            {data.lodge.data.attributes.description}
          </Typography>
        </Box>
        <Box sx={{ display: { xs: 'block', md: 'flex', lg:'flex' }, gap: '30px', mt: 2 }} >
          {data.lodge.data.attributes.room_types.data.map((room) => <RoomCard key={room.id} room={room.attributes} />)}
        </Box>
        <Divider textAlign="left">Offers</Divider>
        {/* <Typography variant="h5" component="h3" marginTop={1}>
          Offers
        </Typography> */}
        <Box sx={{
          display: "flex",
          justifyContent: "left",
          flexDirection: "coloumn",
          gap: 2,
          flexWrap: "wrap",
          margin: "auto"
        }}>
          {Object.keys(data.lodge.data.attributes.offers).map((offer, index) => {
            if (data.lodge.data.attributes.offers[offer] === true) {
              return (
                <Box key={index} >

                  <Button sx={{ color: "black" }}>{offer}<CheckCircleOutlineIcon /></Button>


                </Box>
              )
            } else {
              return null
            }

          })}
        </Box >
        <Divider textAlign="left">Location</Divider>
        <iframe width="100%" height="350" src={data.lodge.data.attributes.mapSrc}><a href="https://www.maps.ie/population/">Population calculator map</a></iframe>
        <Divider textAlign="left">More info</Divider>
        <ControlledAccordions details={data.lodge.data.attributes.contact_details} lodgeId={id} />
        {/* {console.log(data.lodge.data.attributes.offers)} */}
      </Container>
      <Footer />
    </>
  );
}