import React from 'react'
import '../App.css';

const ComingSoon = ({type}) => {
  return (
    <section className="container">
      <div className="clock_content">
        <h1>{type} Coming soon</h1>
        <h3>Stay tuned for something amazing.</h3>
        <button className="button">home page</button>
      </div>
    </section>
  )
}

export default ComingSoon