import React, { useState } from 'react';
import { MDBContainer, MDBCard, MDBCardBody, MDBCardImage, MDBRow, MDBCol, MDBIcon, MDBInput } from 'mdb-react-ui-kit';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import * as yup from "yup";
import FormHelperText from '@mui/material/FormHelperText';
import Global from '../Helper/Global';
import { Button } from '@mui/material';

function ResetPassword() {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [params] = useSearchParams()
  const code = params.get('code')
  const [error, setError] = useState();
  const navigate = useNavigate()

  const schema = yup.object().shape({
    passwordConfirmation:yup.string()
      .oneOf([yup.ref('password')], 'Passwords must match'),
    password: yup.string()
      .required('password is required')
      .min(8, 'password must be atleast 8 characters')
      .max(20, 'password cannot be more than 20 characters'),
      // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"),
  });
  const [loading, setLoading] = React.useState(false);
  const timer = React.useRef();

  const handleLoading = () => {
    if (!loading) {
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setLoading(false);
      }, 2000);
    }

  };

  const handleSubmit = async () => {

    try {
      const obj = {password:password,confirmPassword:confirmPassword};
      await schema.validate(obj);
      axios.post(`${Global}/api/auth/reset-password`, {
        code: code, // code contained in the reset link of step 3.
        password: password,
        passwordConfirmation: confirmPassword,
      })
        .then(response => {
          setError("Success!!! Redirecting.....");
          timer.current = window.setTimeout(() => {
            navigate('/login')
          }, 1500);
        })
        .catch(error => {
          setError('An error occured, check if your passwords matches');
        });
    } catch (err) {
      if (err && err instanceof yup.ValidationError) setError(err.message);
    }


  }
  return (
    <MDBContainer className="my-0">

      <MDBCard>
        <MDBRow className='g-0'>

          <MDBCol md='4'>
            <MDBCardImage src='https://images.pexels.com/photos/2791028/pexels-photo-2791028.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' alt="login form" className='rounded-start w-100' />
          </MDBCol>
          <MDBCol md='5'>
            <MDBCardBody className='d-flex flex-column'>

              <div className='d-flex flex-row mt-2'>
                <MDBIcon fas icon="cubes fa-3x me-3" style={{ color: '#ff6219' }} />
                <span className="h1 fw-bold mb-0">Reset Password</span>
              </div>
              <br />
              <MDBInput onChange={e => setPassword(e.target.value)} name="Password" wrapperClass='mb-4' label='Reset-Password' type='password' size="lg" />
              <MDBInput onChange={e => setConfirmPassword(e.target.value)} name="Password" wrapperClass='mb-4' label='confirm-Password' type='password' size="lg" />
              <FormHelperText sx={{ color: 'red' }}>{error}</FormHelperText>
              <Button onClick={() => {
                  handleSubmit()
                  handleLoading()
                }} sx={{ borderRadius: 1, color: 'black' }} type='submit' variant="outlined">
                  {!loading ? (
                    "submit"
                  ) : (
                    'Loading.....'
                  )}
                </Button>
            </MDBCardBody>
          </MDBCol>
        </MDBRow>
      </MDBCard>
    </MDBContainer>
  );
}

export default ResetPassword;