import React from 'react';
import { MDBFooter } from 'mdb-react-ui-kit';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Paper } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link } from 'react-router-dom';


export default function Footer() {
  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={1}
    >
      <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
        <section className='d-none d-lg-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
              <span>Get connected with us:</span>
              <Link to="https://www.linkedin.com/company/guest-locations/about/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
              <LinkedInIcon sx={{ color: 'Grey', width: 30, height: 30, mr: 1 }} />
              </Link>
              <Link to="https://www.facebook.com/profile.php?id=61554099701390&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
              <FacebookIcon sx={{ color: 'Grey', width: 30, height: 30, mr: 1 }} />
              </Link>
              <Link to="https://www.instagram.com/guest_locations0?igsh=OWs0Nm04a295M2o3" target="_blank" rel="noopener noreferrer">
              <InstagramIcon sx={{ color: 'Grey', width: 30, height: 30, mr: 1 }} />
              </Link>
              <Link to="https://x.com/Guest_Locations?t=LU4iwl9XtYv-YuYK-61gMA&s=09" target="_blank" rel="noopener noreferrer">
              <TwitterIcon sx={{ color: 'Grey', width: 30, height: 30, mr: 1 }} />
              </Link>
              <Link to="https://whatsapp.com/channel/0029VaJ1CFW2phHV7pQbXP0u" target="_blank" rel="noopener noreferrer">
              <WhatsAppIcon sx={{ color: 'Grey', width: 30, height: 30, mr: 1 }}/>
              </Link>
          </div>
        </section>
        <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
          © 2024 Copyright:
          <a className='text-reset fw-bold' href='#'>
            Guest Locations
          </a>
        </div>
      </MDBFooter>
    </Paper>
  );
}