import React, { useState } from 'react';
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBIcon,
} from 'mdb-react-ui-kit';
import { useSignIn } from 'react-auth-kit';
import { useFormik } from 'formik';
import axios, { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup';
import FormHelperText from '@mui/material/FormHelperText';
import { Button, Typography, TextField, InputAdornment } from '@mui/material';
import CircularIndeterminate from '../components/Loading';
import logo from '../Assets/BlueLogo.png';
import Global from '../Helper/Global';

function Login() {
  const [error, setError] = useState();
  const signIn = useSignIn();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const timer = React.useRef();

  const handleLoading = () => {
    if (!loading) {
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  const schema = yup.object().shape({
    password: yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .max(20, 'Password cannot be more than 20 characters'),
    identifier: yup.string().email('Invalid email format').required('Email is required'),
  });

  const onSubmit = async (values) => {
    setError('');

    try {
      await schema.validate(values);
      const response = await axios.post(
        `${Global}/api/auth/local`,
        values
      );

      if (response.data.user.confirmed) {
        signIn({
          token: response.data.jwt,
          expiresIn: 3600,
          tokenType: 'Bearer',
          authState: { email: response.data.user.id },
        });

        const data = await axios.get(`${Global}/api/users/${response.data.user.id}?populate=user_info`);
        if (data.data.user_info === null) {
          navigate('/createprofile');
        } else if (data.data.user_info.lodgeAdmin) {
          navigate('/dashboard');
        } else {
          navigate('/');
        }
      }
    } catch (err) {
      if (err && err instanceof AxiosError) {
        setError('Incorrect Email or Password and/or Account not verified');
      } else if (err && err instanceof Error) {
        setError(err.message);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      identifier: '',
      password: '',
    },
    onSubmit,
  });

  return (
    <MDBContainer className="my-3">
      <form onSubmit={formik.handleSubmit}>
        <MDBCard>
          <MDBRow className='g-0'>
            <MDBCol md='6' className='d-flex justify-content-center align-items-center'>
              <img
                src={logo}
                alt="Logo"
                style={{
                  maxWidth: window.innerWidth < 600 ? '20%' : '80%',
                  height: 'auto',
                }}
              />
            </MDBCol>

            <MDBCol md='6'>
              <MDBCardBody className='d-flex flex-column'>
                <div className='d-flex flex-row mt-2'>
                  <MDBIcon fas icon="cubes fa-3x me-3" style={{ color: '#ff6219' }} />
                  <span className="h1 fw-bold mb-0">Login</span>
                </div>

                <h5 className="fw-normal my-4 pb-3" style={{ letterSpacing: '1px' }}>Sign into your account</h5>

                <TextField
                  fullWidth
                  name="identifier"
                  value={formik.values.identifier}
                  onChange={formik.handleChange}
                  margin="normal"
                  label="Email address"
                  placeholder="Enter your email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MDBIcon fas icon="envelope" />
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  fullWidth
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  margin="normal"
                  label="Password"
                  type="password"
                  placeholder="Enter your password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MDBIcon fas icon="lock" />
                      </InputAdornment>
                    ),
                  }}
                />

                <FormHelperText sx={{ color: 'red' }}>{error}</FormHelperText>

                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleLoading}
                  style={{ marginTop: '20px' }}
                >
                  {!loading ? 'Login' : <CircularIndeterminate />}
                </Button>

                <Typography
                  sx={{ cursor: 'pointer', marginTop: '10px' }}
                  component="a"
                  onClick={() => navigate('/forgotpassword')}
                >
                  Forgot password?
                </Typography>

                <p className="mb-5 pb-lg-2" style={{ color: '#393f81' }}>
                  Don't have an account?{' '}
                  <Typography
                    sx={{ cursor: 'pointer' }}
                    component="a"
                    onClick={() => navigate('/signup')}
                    style={{ color: '#393f81' }}
                  >
                    Register here
                  </Typography>
                </p>

                <div className='d-flex flex-row justify-content-start'>
                  <a href="/" className="small text-muted me-1">Terms of use.</a>
                  <a href="#/" className="small text-muted">Privacy policy</a>
                </div>
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </MDBCard>
      </form>
    </MDBContainer>
  );
}

export default Login;
