import axios from "axios";
import jwt_decode from 'jwt-decode';
import { useState } from "react";
import Global from "./Global";

function getCookie() {
    var pattern = RegExp("_auth" + "=.[^;]*")
    var matched = document.cookie.match(pattern)
    if (matched) {
        var cookie = matched[0].split('=')
        return cookie[1]
    }
    return false
}

export function getUserid() {

    try {
        let newToken = getCookie()
        let decode = jwt_decode(newToken)
        const id = decode.id
        return id
    } catch (error) {
        console.log(error);
    }

}

export async function updateUser(response) {
    try {
        let id = getUserid()
        const data = await axios.put(`${Global}/api/users/${id}`, response);

        return Promise.resolve({ data })
    } catch (error) {
        return Promise.reject({ error: "Couldn't Update Profile...!" })
    }
}

export async function updateUserInfo(response) {
    try {

        // const token = await localStorage.getItem('token');
        let id = getUserid()
        const data = await axios.put(`${Global}/api/user-infos/${id}`, response);

        return Promise.resolve({ data })
    } catch (error) {
        return Promise.reject({ error: "Couldn't Update Profile...!" })
    }
}

export async function updateBooking(id, _data) {
    // try {

    let data = {};
    data['isBookingFullfilled'] = _data
    const response = await axios.put(`${Global}/api/bookings/${id}`, { data: data });

    return Promise.resolve({ response })
    // // } catch (error) {
    //     return Promise.reject({ error : "Couldn't Update booking...!"})
    // }
}

export async function updateRoomType(roomId, numOfRooms) {
    // try {
    const availRooms = await axios.get(`${Global}/api/room-types/${roomId}`)
    if (availRooms.data.data.attributes.numOfRooms >= numOfRooms) {
        const update = availRooms.data.data.attributes.numOfRooms - numOfRooms
        let data = {};
        data['numOfRooms'] = update
        // console.log(data);
        const response = await axios.put(`${Global}/api/room-types/${roomId}`, { data: data });


        return Promise.resolve({ response })
    }

    // // } catch (error) {
    //     return Promise.reject({ error : "Couldn't Update booking...!"})
    // }
}