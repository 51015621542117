import React, { useEffect, useState } from 'react';
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardText, MDBCardBody, MDBCardImage } from 'mdb-react-ui-kit';
import { useIsAuthenticated } from 'react-auth-kit';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuthUser } from 'react-auth-kit'
import { useQuery, gql } from '@apollo/client';
import Edit from '../components/Edit'
import { getUserid } from '../Helper/Helper';
import CircularIndeterminate from '../components/Loading';



const GET_USER = gql`
  query GetUser($id:ID!){
    usersPermissionsUser(id:$id){
      data{
        id
        attributes{
          email
          username
      user_info{
            data{
              attributes{
                lastName
                province
                firstName
                phoneNumber
                dp{data{attributes{url}}}
              }
            }
          }
            }
          }
    }
  }
`;

export const Profile = () => {

  let id  = getUserid()

  const { loading, error, data } = useQuery(GET_USER, {
    variables: { id: id },
  });

  
    

  if (loading) return <CircularIndeterminate />;
  if (error) return <p>Error : {error.message}</p>;

  return (
    <section >
      <MDBContainer className="py-5">
        <MDBRow>
          <MDBCol lg="4">
            <MDBCard className="mb-4">
              <MDBCardBody className="text-center">
                <MDBCardImage
                  src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
                  // src={`http://localhost:1337${data?.usersPermissionsUser.data.attributes.user_info.data.attributes.dp.data.attributes.url}`}
                  alt="avatar"
                  className="rounded-circle"
                  style={{ width: '460px' }}
                  fluid />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol lg="8">
            <MDBCard className="mb-4">
              <MDBCardBody>
              <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Lastname</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <div className="text-muted"><Edit type={data?.usersPermissionsUser.data.attributes.user_info.data.attributes.lastName } field="lastName" /></div>
                  </MDBCol>
                </MDBRow>
                <hr />
              <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Firstname</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <div className="text-muted"><Edit type={data?.usersPermissionsUser.data.attributes.user_info.data.attributes.firstName } field="firstName" /></div>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Username</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <div className="text-muted"><Edit type={data?.usersPermissionsUser.data.attributes.username } field="username" /></div>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Email</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <div className="text-muted"> <Edit type={data?.usersPermissionsUser.data.attributes.email } field="email" /> </div>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Mobile Number</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <div className="text-muted"><Edit type={data?.usersPermissionsUser.data.attributes.user_info.data.attributes.phoneNumber } field="phoneNumber" /></div>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Province</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <div className="text-muted"><Edit type={data?.usersPermissionsUser.data.attributes.user_info.data.attributes.province } field="province" /></div>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
}